import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Fade from "react-reveal/Fade"
import { LinkedinShareButton, TwitterShareButton } from "react-share"
import Snowfall from "react-snowfall"
import SVGIcon from "../components/common/SVGIcon"
import Frame from "../components/common/frame"
import "../styles/pages/christmas.scss"

function Christmas() {
  const [hide, SetHide] = useState(false)
  const [loader, SetLoader] = useState(true)

  const seo = {
    title: "Happy Holidays from SuperOps",
    image: {
      url: "https://media.graphassets.com/OXQBlQjdSIa1fzhQeaER",
    },
  }
  const location = useLocation()
  useEffect(() => {
    let vidElement = document.getElementById("igloo")
    window.addEventListener("scroll", () => {
      let h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight"

      let percent =
        ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 90
      vidElement.style.opacity = 1 - percent / 100 + 0.1
    })
  }, [])

  useEffect(() => {
    let fired = 0

    setTimeout(() => {
      SetLoader(false)
      window.addEventListener("scroll", () => {
        if (fired === 0) {
          fired = 1
          SetHide(true)
        }
      })
    }, 2000)
  }, [])

  return (
    <div className="holiday-page">
      <Frame
        IsFooterVisble="No"
        seo={seo}
        theme="dark"
        bodyClassName="holidaybody"
      >
        <div id="igloo" className="spl-container d-flex justify-content-center">
          <video loop autoPlay muted>
            <source
              src="https://media.graphassets.com/uClaoiKQlq1tPLO2re5M"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div style={{ display: loader ? "none" : "block" }}>
          <div className="wrap-scrol"></div>
          <div
            className="d-flex justify-content-center prelative scroll-btn"
            style={{ opacity: hide ? 0 : 1 }}
          >
            <Fade bottom duration={500} distance="10px">
              <img
                src="https://media.graphassets.com/QwWVBLT8mmKdoFYQ6AWQ"
                alt="scroll"
              />
            </Fade>
          </div>

          <div
            id="content-wrapper"
            className="text-container mx-auto prelative text-center"
          >
            <Snowfall
              snowflakeCount={100}
              speed={[0, 1.5]}
              radius={[0.5, 2]}
              wind={[-0.5, 1]}
              color="#BAC2D6"
            />
            <div className="text-img mx-auto">
              <img
                src="https://media.graphassets.com/mzLw1CXgStKJeYFUXyta"
                className="mb50 w-100"
                alt="textholiday"
              />
            </div>
            <p className="mx-auto mb50 message">
              Thank you for your continued trust in us. We hope to celebrate
              more successes with you in the coming year
            </p>
            <div className="mb50 mt16">
              <p className="p14">share this now:</p>
              <div className="d-flex g-3 social-icons justify-content-center">
                <TwitterShareButton title={seo.title} url={location.href}>
                  <SVGIcon name={`twitterBig`} className="social-icon" />
                </TwitterShareButton>
                <LinkedinShareButton title={seo.title} url={location.href}>
                  <SVGIcon name={`linkedbig`} className="social-icon" />
                </LinkedinShareButton>
              </div>
            </div>
            <img
              src="https://media.graphassets.com/0J3zi7kETLi7NNr5LShr"
              loading="lazy"
              alt="tree"
              className="mb50"
            />
            <div className="d-flex justify-content-center align-items-center signature">
              <p className="m-0 p16">Made with &#10084;&#65039; from</p>
              <Link to="/">
                <SVGIcon name="SuperOps-logo-dark" />
              </Link>
            </div>
          </div>
        </div>
      </Frame>
      <Snowfall
        snowflakeCount={100}
        speed={[0, 1.5]}
        radius={[0.5, 2]}
        wind={[-0.5, 1]}
        color="#BAC2D6"
      />
    </div>
  )
}

export default Christmas
